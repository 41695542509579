<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="getTitleDatos">
						<v-row dense>
							<v-col cols="12">
								<v-row dense v-if="!isCreatingElement">
									<!-- ID -->
									<v-col cols="12" md="2">
										<pui-number-field
											:id="`idcatestiba-mimdgcategoriaestiba`"
											v-model="model.idcatestiba"
											:label="$t('mimdgcategoriaestiba.idcatestiba')"
											disabled
											required
											toplabel
											integer
											min="0"
											max="99999"
										></pui-number-field>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- CODIGO -->
									<v-col cols="12" md="2">
										<pui-text-field
											:id="`codigo-mimdgcategoriaestiba`"
											v-model="model.codigo"
											:label="$t('mimdgcategoriaestiba.codigo')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="10"
										></pui-text-field>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- DESCRIPCION -->
									<v-col cols="12" md="6">
										<pui-text-field
											:id="`descripcion-mimdgcategoriaestiba`"
											v-model="model.descripcion"
											:label="$t('mimdgcategoriaestiba.descripcion')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="100"
										></pui-text-field>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- FECBAJA -->
									<v-col cols="12" md="4">
										<pui-date-field
											:id="`fecbaja-mimdgcategoriaestiba`"
											v-model="model.fecbaja"
											:label="$t('mimdgcategoriaestiba.fecbaja')"
											:disabled="formDisabled"
											toplabel
											time
										></pui-date-field>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<!-- MINI AUDIT -->
						<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
					</pui-field-set>
				</v-col>
			</v-row>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading
			v-else
		></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'mimdgcategoriaestiba-form',
	mixins: [PuiFormMethodsMixin],
	components: {
	},
	data() {
		return {
			modelName: 'mimdgcategoriaestiba'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
		getTitleDatos() {
			return this.$t('form.mimdgcategoriaestiba');
		}
	},
	created() {
	}
}
</script>
